<template>
	<div class="trialagreement">
		<!-- 导航栏 -->
		<van-nav-bar
		class="nav_bar_color"
		title="试乘试驾协议"
		left-text="返回"
		left-arrow
		right-text="刷新页面"
		right-arrow
		@click-left="onClickLeft"
		@click-right="onClickright"
		fixed
		z-index="11"
		placeholder 
		:border="false"
		/>
		
		<!-- 二维码 -->
		<div style="height: 16px;"></div>
		<div class="qr">
			<div class="qr__title">
				试乘试驾电子协议
			</div>
			<div class="qr__init">
				请顾客先扫描以下企业微信二维码添加好友
			</div>
			<div class="qrimg">
				<div @click="gouser" class="qrimg__btn" v-if="wechat_qr_image == '' || wechat_qr_image == null">
					<div class="qrimg__btn__row1">
						尚未上传
					</div>
					<div class="qrimg__btn__row1">
						企业微信二维码
					</div>
					<div class="qrimg__btn__row2">
						点击设置
					</div>
				</div>
				<img v-else :src="wechat_qr_image" >
			</div>
			<button class="qr__btn" @click="onshare">
				发送给客户
			</button>
		</div>
		
		<!-- 设置项 -->
		<div class="opt" @click="handDrive">
			<div class="opt__title">
				客户驾照
				<text class="opt__status">{{info.driving_license_status == 1 ? '已上传' : '未上传'}}</text>
			</div>
			<div class="opt__btn1" v-if="info.driving_license_status == 1">点击查看</div>
			<div class="opt__btn2" v-if="info.driving_license_status != 1">尚未上传</div>
		</div>
		<div class="opt">
			<div class="opt__title">
				客户签字
				<text class="opt__status">{{info.signature_status == 1 ? '已上传' : '未上传'}}</text>
			</div>
			<div class="opt__btn1" @click="gopath(info.signature_photo_images)" v-if="info.signature_status == 1">点击查看</div>
			<div class="opt__btn2" v-if="info.signature_status != 1">尚未上传</div>
		</div>
		
		<!-- 保存 -->
		<div style="height: 72px;"></div>
		<div v-if="info.button_status != 0 && info.button_status" @click="savebtn(info.button_status)" class="savebtn">
			<div :class="{'iscolor':info.button_status == 1}">协议内容已完整</div>
		</div>


	</div>
</template>

<script>
import {getsignature} from '@/utils/utils'
import { Dialog } from 'vant';
export default {
	data() {
		return {
			wechat_qr_image: '',
			info: {
				number: '',
			},

		};
	},
	mounted() {

		if (this.$route.query?.number) this.info.number = this.$route.query.number
		this.getAgreementInfo()
		this.getUserInfo()
		this.$wx.onMenuShareAppMessage({
				title: '试乘试驾电子协议', // 分享标题
				desc: '', // 分享描述
				link: 'https://h5.1des1.com.cn/scsj/scsjxy/?number=' + this.info.number + '#/Home', // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
				imgUrl: 'https://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/logo.jpeg', // 分享图标
				enableIdTrans: 1, // 是否开启id转译，不填默认为0
				success: function (res) {
					this.$toast.success('分享成功')
						// 用户确认分享后执行的回调函数
				},
				error: function(res) {
					getsignature()
				},
				cancel: function () {
					this.$toast.success('取消分享')
						// 用户取消分享后执行的回调函数
				}
		});
	},
	methods: {
		 async savebtn(v){
			if(v!=1) return ''
			Dialog.confirm({
					title: '确认已完整',
					message: '请确认协议内容是否已完整，确认后将无法修改',
				}).then(async () => {
					const {data:res} = await this.$http.post('/api/scsj/complete_scsj_treaty',{number:this.info.number})
					if(res.code == 1){
						this.$toast.success('确认成功')
						this.onClickright()
						}
				}).catch(() => {
					// on cancel
				});
		},
		onClickright(){
			this.getAgreementInfo()
			this.getUserInfo()
		},
		onshare(){
			const url = localStorage.getItem('share')
			this.$wx.invoke(
						"shareAppMessage", {
							title: '试乘试驾电子协议', // 分享标题+ '#/Home
							desc: '', // 分享描述
							link: url + '/scsj/scsjxy/?number=' + this.info.number , // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
							imgUrl: 'https://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/logo.jpeg', // 分享图标
							enableIdTrans: 0, // 是否开启id转译，不填默认为0
						}, function(res) {
								if (res.err_msg == "shareAppMessage:ok") {
									this.$toast.success('分享成功')						//正确处理
								}else {
									getsignature()					//错误处理
								}
						}
					);
		},
		// 用户详情
		async getUserInfo(){
			const {data: result} = await this.$http.post('/api/user/get_userinfo_1_5_1')
			if (result.code == 1) {
				this.wechat_qr_image = result.data.res.wechat_qr_image
			}
		},
		// 修改二维码
		gouser(){
			this.$router.push({
				path: `/useredit`
			})
		},
		gopath(img){
			this.$router.push({
				path: `viewimg/?url=${this.serverUrl(img)}`
			})
		},
		// 驾照
		handDrive(e) {
			if (this.info.driving_license_status != 1) {
				return
			}
			this.$router.push({
				path: `/trial-drive?number=${this.info.number}`
			})
		},
		
		// 协议详情
		async getAgreementInfo(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_scsj_treaty_info', {
				number: this.info.number
			})
			if (result.code == 1) {
				this.info = { ...this.info, ...result.data.res }
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.qr{
	margin: 0 auto;
	width: 686px;
	background: #222222;
	border-radius: 16px;
	padding: 32px 0;
	text-align: center;
	&__title{
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		color: #FFFFFF;
		line-height: 36px;
	}
	&__init{
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		color: #9B9B9B;
		line-height: 36px;
	}
	&__btn{
		margin: 60px auto 0;
		width: 280px;
		border-radius: 8px;
		border: 1px solid rgba(255, 255, 255, 0.3);
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		color: #FFFFFF;
		text-align: center;
		line-height: 72px;
		background: rgba(255, 255, 255, 0);
	}
	.qrimg{
		margin: 40px auto 0;
		width: 320px;
		height: 320px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid #fff;
		&__btn{
			&__row1{
				font-size: 32px;
				font-family: HYQiHeiY3-45;
				color: #9B9B9B;
				line-height: 36px;
			}
			&__row2{
				margin-top: 10px;
				font-size: 32px;
				font-family: HYQiHeiY3-45;
				color: #fff;
				line-height: 36px;
			}
		}
		img{
			width: 100%;
			height: 100%;
		}
	}
}
.opt{
	margin: 40px auto 0;
	padding: 0 32px;
	width: 686px;
	box-sizing: border-box;
	height: 156px;
	background: #373C46;
	border-radius: 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	&__title{
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		color: #FFFFFF;
	}
	&__status{
		margin-left: 20px;
		font-size: 28px;
		font-family: HYQiHeiY3-45;
		color: #9B9B9B;
	}
	&__btn1{
		width: 172px;
		border-radius: 8px;
		border: 1px solid rgba(255, 255, 255, 0.3);
		font-size: 32px;
		font-family: HYQiHeiY3-55;
		color: #ECECEC;
		text-align: center;
		line-height: 64px;
	}
	&__btn2{
		width: 172px;
		border-radius: 8px;
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		color: #9B9B9B;
		text-align: center;
		line-height: 64px;
	}
}
.savebtn{
	background: #191919;
	position: fixed;
	box-sizing: border-box;
	bottom: 0;
	padding: 22px 40px;
	padding-bottom: calc(constant(safe-area-inset-bottom) + 22px);
	padding-bottom: calc(env(safe-area-inset-bottom) + 22px);
	width: 100%;
	div{
		width: 100%;
		background: #6F6F6F;
		text-align: center;
		line-height: 92px;
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		color: #FFFFFF;
	}
}

.iscolor{
	background: #fa0037 !important;
}

</style>
